<template>
  <popup :width="width" :heightPopup="height" :closePopupEvent="closePopup">
    <div slot="header">
      <h6 class="login-header">Please enter your Mobile No</h6>
    </div>
    <div class="vlt-container">
      <div class="change-details">
        <div class="vlt-input-group input-ico">
          <label>Mobile No</label>
          <div class="vlt-input">
            <input
              type="text"
              aria-label="mobile no"
              v-model="mobileNo"
              placeholder="Please enter your mobile No"
              autocomplete="off"
              @focusin="actFocused"
            />
            <p class="error-message">{{ mobileError }}</p>
          </div>
        </div>

        <app-loader v-show="showLoading"></app-loader>

        <div class="change-btn">
          <button
            type="button"
            class="btn-login"
            aria-label="Change"
            @click="actContinue"
          >
            CONTINUE
          </button>
        </div>
      </div>
    </div>
  </popup>
</template>

<script>
import { EventBus } from "@/main";
import { mapActions, mapGetters, mapMutations } from "vuex";
// import SimpleCrypto from "simple-crypto-js";

export default {
  data() {
    return {
      width: "60%",
      height: "80%",
      mobileError: "",
      showLoading: false,
      mobileNo: "",
      social: {},
    };
  },
  computed: {
    ...mapGetters(["subscriberId"]),
  },
  props: ["closePopupCB", "page"],
  methods: {
    ...mapMutations(["CommitAppUser"]),
    ...mapActions(["actProfileUpdate"]),
    closePopup() {
      this.closePopupCB();
    },
    actFocused() {
      this.mobileError = "";
    },
    verifyNumber(number) {
      let regEx = /^[0-9]{10}$/;
      return regEx.test(number);
    },
    validateMobile() {
      this.formError = null;
      if (!this.mobileNo) {
        this.mobileError = "Please enter mobile number";
        return false;
      } else if (!this.verifyNumber(this.mobileNo)) {
        this.mobileError = "Please enter valid mobile number";
        return false;
      }
      return true;
    },
    actContinue() {
      if (this.validateMobile()) {
        let param = {};
        param.contactno = this.mobileNo;
        param.subscriberid = this.subscriberId;
        this.showLoading = true;
        this.actProfileUpdate(param)
          .then((response) => {
            this.showLoading = false;
            if (response.error) {
              this.mobileError = response.reason;
            } else {
              this.CommitAppUser({contactno:this.mobileNo});
              this.$emit('mobileNumber', this.mobileNo);
              this.errorMessage = "";
              this.closePopupCB();

            }
          })
          .catch((error) => {
            this.showLoading = false;
            console.log(error);
            if (error.status === 401) {
              // this.unauthorisedHandler();
              if (this.flagAutologin) {
                this.commonAutoLogin(this.actContinue);
                return;
              }
            }
          });
      }
    },
  },
  created() {},
  beforeDestroy() {},
  components: {
    popup: () =>
      import(/* webpackChunkName: "Popup" */ "@/components/Shared/popup.vue"),
    "app-loader": () =>
      import(
        /* webpackChunkName: "appLoader" */ "@/components/Shared/templates/appLoader.vue"
      ),
  },
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";

.vlt-container {
  font-family: $font-family;
  font-weight: $font-weight-regular;

  .change-details {
    padding: 0% 20% 3%;
    .error-message {
      margin-top: 3%;
      color: $font-clr-red;
      text-align: left;
    }
    .vlt-input-group {
      margin-top: 5%;
      padding: 0 10%;
    }
    .change-input {
      border: 1px solid $clr-bg-gray-light-4;
      border-radius: 2px;
      width: 100%;
      height: 30px;
      padding: 3%;
      margin-top: 2%;
      color: $font-clr-white;
      caret-color: $font-clr-white;
    }
    .change-input-normal {
      background: $clr-bg-gray-light-3 0% 0% no-repeat padding-box;
    }
    .change-input-focused {
      background: $clr-bg-gray-dark-1 0% 0% no-repeat padding-box;
    }
    p {
      font-family: $font-family;
      font-weight: $font-weight-medium;
      font-size: 14px;
      text-align: center;
      color: $font-clr-gray;
      //   margin-top: 5%;
    }
    label {
      font-family: $font-family;
      font-weight: $font-weight-medium;
      margin-top: 0%;
      margin-bottom: 0%;
    }
    input {
      margin-top: 2%;
      padding: 2%;
    }
    .change-btn {
      margin: 10% 0% 0%;
      padding: 0 10%;
    }
    .ico-img {
      top: 15px;
    }
    .change-input-focused {
      background: $clr-bg-gray-dark-1 0% 0% no-repeat padding-box;
    }
  }
}
</style>